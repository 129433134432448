import { InputRadio } from '~/components/BaseForm/core/primitives'

const Civility: typeof InputRadio = {
  ...InputRadio,
  attr: {
    vertical: true,
    rules: 'required',
    labelTranslateKey: 'form.representativeCivility',
    name: 'civility',
  },
  containerInputClass: 'w-full md:w-1/2 md:pr-10',
  options: [
    { value: 'mrs', labelTranslateKey: 'form.civilityFemale' },
    { value: 'mr', labelTranslateKey: 'form.civilityMale' },
    {
      value: 'unspecified',
      labelTranslateKey: 'form.civilityUnspecified',
    },
  ],
}

export default Civility
