<script setup lang="ts">
defineOptions({
  inheritAttrs: false,
})

const emits = defineEmits<{
  'update:otp': [value: string]
  'reset-errors': [value: unknown[]]
}>()

const props = withDefaults(
  defineProps<{
    digitCount: number
    errors?: unknown[]
  }>(),
  {
    errors: () => [],
  },
)

const digits = reactive<string[] | null[]>(new Array(props.digitCount).fill(''))
const otpContainer = ref<HTMLElement>()

onMounted(() => {
  otpContainer?.value?.children[0].focus()
})

watch(
  () => digits,
  (newVal) => {
    if (
      newVal.length === props.digitCount &&
      doesNotContainEmptyStrings(newVal)
    ) {
      emits('update:otp', newVal.join(''))
    } else {
      emits('reset-errors', [])
      emits('update:otp', '')
    }
  },
  { deep: true },
)

const doesNotContainEmptyStrings = (arr: string[] | null[]) => {
  return arr.every(
    (element) => typeof element === 'string' && element.trim() !== '',
  )
}

const handleInput = function (value: string, index: number) {
  if (/^[0-9]$/.test(value)) {
    digits[index] = value

    if (index !== props.digitCount - 1) {
      otpContainer?.value?.children[index + 1].focus()
    }
  }
}

const handleKeyDown = function (event: KeyboardEvent, index: number) {
  const ALLOWED_KEYS = ['Tab', 'ArrowRight', 'ArrowLeft', 'Meta', 'v']

  if (!ALLOWED_KEYS.includes(event.key)) {
    event.preventDefault()
  }

  if (event.key === 'Backspace') {
    digits[index] = null

    if (index !== 0) {
      otpContainer?.value?.children[index - 1].focus()
    }

    return
  }

  if (/^[0-9]$/.test(event.key)) {
    digits[index] = event.key

    if (index !== props.digitCount - 1) {
      otpContainer?.value?.children[index + 1].focus()
    }
  }
}

const handlePaste = (e: ClipboardEvent, el: number) => {
  const pasteData = e.clipboardData?.getData('text') as string
  const limitedPasteData = pasteData?.slice(0, props.digitCount)
  const containsNonDigits = /\D/.test(pasteData)

  let nextEl = otpContainer?.value?.children[0]

  if (containsNonDigits || el !== 1) {
    e.preventDefault()
  }

  if (limitedPasteData && !containsNonDigits) {
    for (let i = 1; i < limitedPasteData.length; i++) {
      if (nextEl) {
        digits[i] = limitedPasteData[i]
        nextEl = otpContainer?.value?.children[i]
      }
    }
  }
}
</script>

<template>
  <div ref="otpContainer" v-bind="$attrs" class="flex justify-around">
    <input
      v-for="(el, idx) in digitCount"
      :key="idx"
      :value="digits[idx]"
      :class="[
        'mr-4 size-10 border-b-2 text-center text-5xl',
        errors.length
          ? 'border-error text-error'
          : 'border-gray-700 text-gray-700',
        { bounce: digits[idx], shake: errors.length },
      ]"
      inputmode="numeric"
      maxlength="1"
      type="text"
      @keydown="handleKeyDown($event, idx)"
      @input="handleInput(($event.target as HTMLInputElement).value, idx)"
      @paste="handlePaste($event, el)"
    />
  </div>
  <p v-if="errors.length" class="mt-4 text-error">
    {{ $t('auth.invalidCode') }}
  </p>
</template>

<style scoped>
.bounce {
  animation: pulse 0.3s ease-in-out alternate;
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}

.shake {
  animation: shake 0.8s ease both;
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
