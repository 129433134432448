<script setup lang="ts">
import type { Locale } from 'lc-services/types'
import groupFields from './form/emailForm'
import { useButtons } from '~/components/BaseForm/compose/use-buttons'

defineOptions({
  inheritAttrs: false,
})
const props = defineProps<{
  inputEmail: string
}>()
const emits = defineEmits<{
  'on-success-email': [{ navigation: string; isEmailSignUp: boolean }]
  'update:email': [value: string]
}>()
defineSlots<{ 'step-1-before-form'(): any; 'step-1-after-form'(): any }>()

const { $lcServicesAuth } = useNuxtApp()
const { authController, authPresenter } = $lcServicesAuth
const { t, locale } = useI18n()

const { btnPrimary } = useButtons()

btnPrimary.attrs.blockFull = true
btnPrimary.text = computed(() => t('auth.continue'))
const groupFieldsForm = reactive(groupFields())

const initialData = reactive({
  email: props.inputEmail,
})

const mutation = (data: { email: string }) => ({
  request: async () => {
    await authController.sendTokenByEmail({
      email: data.email,
      locale: locale.value as Locale,
    })
    if (authPresenter.vm.errors.length) {
      return Promise.reject(authPresenter.vm.errors)
    }
    return { ...authPresenter.vm }
  },
})

const onSubmit = () => ({
  success: ({ msg, navigation }: { msg: string; navigation: string }) => {
    emits('on-success-email', {
      navigation,
      isEmailSignUp: msg === 'emailSignUp.success',
    })
  },
  error: (
    err: { errors: { fieldName: string; error: string }[] },
    setFieldError: (field: string, trad: string) => void,
  ) => {
    if (
      err?.errors[0]?.fieldName === 'email' &&
      err?.errors[0]?.error === 'locked'
    ) {
      setFieldError('email', t('error.locked'))
    }
    if (
      err?.errors[0]?.fieldName === 'email' &&
      err?.errors[0]?.error === 'invalid'
    ) {
      setFieldError('email', t('error.invalid_email'))
    }
  },
})
</script>

<template>
  <div class="w-full">
    <slot name="step-1-before-form" />
    <BaseForm
      id-form="form-email"
      :active-error-scroll-to="false"
      :active-modal-errors="false"
      :active-modal-save-or-quit="false"
      :button-primary="btnPrimary"
      :disabled-primary-button="!Boolean(inputEmail)"
      :group-fields="groupFieldsForm"
      :initial-data="initialData"
      :mutation="mutation"
      :on-submit="onSubmit"
      container-button-class="mt-8 mb-4"
      @on-field-update="
        (_, value) => $emit('update:email', value.toLowerCase())
      "
    />
    <slot name="step-1-after-form" />
  </div>
</template>
