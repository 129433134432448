import { Email } from '~/components/BaseForm/core/fields'

import type { CoreGroupFields } from '~/components/BaseForm/types'

export default (): CoreGroupFields => {
  const email: typeof Email = {
    ...Email,
    attr: {
      ...Email.attr,
      autofocus: true,
      id: 'email',
      labelTranslateKey: 'field.email',
      placeholderTranslateKey: 'placeholders.email',
      name: 'email',
      spellcheck: 'false',
      'data-cy': 'email',
    },
    containerInputClass: 'w-full',
  }

  return {
    fields: [email],
  }
}
